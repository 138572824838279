import Swiper from "swiper";
import { Navigation } from 'swiper/modules';

const heroMovieSections = document.querySelectorAll(".hero--movie");

const slogans = document.querySelectorAll('.hero__slogan');
const oneSecond = 1000;
const oneSloganAnimationFullTime = 5 * oneSecond;

const animateText = (element) => {
    return new Promise(resolve => {
       setTimeout(() => {
        const hasTextInClass = Array.from(slogans).some(slogan => slogan.classList.contains('text-in'));
        if (!hasTextInClass) {
            element.classList.add('text-in');

            setTimeout(() => {
                element.classList.add('text-out');
                element.classList.remove('text-in');

                setTimeout(() => {
                    element.classList.remove('text-out');
                    resolve();
                }, oneSecond);
            }, 4 * oneSecond);
        }
       }, 500)
    });
};

const animateSlogans = async () => {
    for (const element of slogans) {
        await animateText(element, slogans);
    }
};

let intervalId; 

const startAnimation = () => {
    if (intervalId) {
        clearInterval(intervalId);
    }
    animateSlogans();
    const intervalTime = slogans.length * oneSloganAnimationFullTime;
    intervalId = setInterval(() => {
        animateSlogans();
    }, intervalTime + 500);
}

if (heroMovieSections.length === 0) {
    startAnimation();
}

/// ##### Swiper

const heroSections = document.querySelectorAll('.hero__swiper-slider');

if (heroSections) {
    Swiper.use([Navigation]);
    let heroArray = [];

    const initSwiper = () => {
        heroSections.forEach((section, i) => {
            let init = false;
            const rightArrow = section.querySelector(".hero__right-arrow");
            section.classList.add(`hero__swiper-slider-${i}`);
            if (!init && window.innerWidth < 991.98) {
                init = true;
                if (section.querySelector('.swiper-wrapper')) {
                    const heroCarousel = new Swiper(`.hero__swiper-slider-${i}`, {
                        slidesPerView: 'auto',
                        slideActiveClass: 'is-active',
                        loop: 1,
                        breakpoints: {
                            0: {
                                slidesPerView: 1.25,
                                spaceBetween: 0,
                            },
                            575: {
                                slidesPerView: 2.25,
                                spaceBetween: 0,
                            }
                        },
                        navigation: {
                            nextEl: rightArrow,
                        },
                    });
                    heroArray.push(heroCarousel);
                }
            } else if (window.innerWidth > 991.98) {
                heroArray.forEach((item => {
                    item.destroy();
                }))
                init = false;
            }
        })
    }

    initSwiper();
    window.addEventListener('resize', initSwiper);
}


/// ##### Animated icon

const iconsWrapper = document.querySelectorAll('.hero__icon-wrapper');

if (iconsWrapper && iconsWrapper.length > 0) {
    window.addEventListener('scroll', () => {
        iconsWrapper.forEach((iconWrapper) => {
            const boundingRect = iconWrapper.getBoundingClientRect();
            const viewportHeight = window.innerHeight;
            const percentage = Math.max(
                0,
                Math.min(
                    100,
                    ((viewportHeight - boundingRect.top) /
                        (viewportHeight + boundingRect.height)) *
                        100
                )
            );

            if (percentage >= 50 && percentage <= 75) {
                iconWrapper.classList.add('active-1');
            } else if (percentage > 75) {
                iconWrapper.classList.remove('active-1');
                iconWrapper.classList.add('active-2');
            } else if (percentage < 35) {
                iconWrapper.classList.remove('active-1');
                iconWrapper.classList.remove('active-2');
            }
        })
    })
}

/// #### Parallax

const parallaxSections = document.querySelectorAll('.hero__background-image--parallax');

if (parallaxSections && parallaxSections.length > 0) {
    window.addEventListener('scroll', () => {
        parallaxSections.forEach(section => {
            const boundingRect = section.getBoundingClientRect();
            const viewportHeight = window.innerHeight;
            const percentage = Math.max(
                0,
                Math.min(
                    100,
                    ((viewportHeight - boundingRect.top) /
                        (viewportHeight + boundingRect.height)) *
                        100
                )
            );

            section.style.backgroundPosition = `50% ${parseFloat(
                percentage / 3
            )}%`;
        });
    });
}


/// ### Movie handling

if (heroMovieSections && heroMovieSections.length > 0) {
    let hasAnimationStarted = false;
    const handleVideo = (backgroundImages, videoSrcElement, videoElement, newSource) => {
        backgroundImages.forEach(item => item.classList.remove("-hide"));
        videoSrcElement.src = newSource;
        videoElement.load();

        videoElement.addEventListener("loadeddata", () => {
            videoElement.play();
            backgroundImages.forEach(item => item.classList.add("-hide"));
            if (!hasAnimationStarted) {
                startAnimation();
                hasAnimationStarted = true;
            }
        })
    }

    const handleVideoSection = (section) => {
        const videoElement = section.querySelector("video");
        const backgroundImages = section.querySelectorAll(".hero__background-image");
        const videoSrcElement = videoElement.querySelector("source");
        const source = videoSrcElement.src;
        const windowWidth = window.innerWidth;

        if (windowWidth > 991.98) {
            const videoSrc = videoElement.getAttribute("data-desktop");
            if (videoSrc !== source) {
                handleVideo(backgroundImages, videoSrcElement, videoElement, videoSrc);
            }
        } else {
            const videoSrc = videoElement.getAttribute("data-mobile");
            
            if (videoSrc !== source) {
                handleVideo(backgroundImages, videoSrcElement, videoElement, videoSrc);
            }
        }
        
    }

    window.addEventListener("load", () => {
        heroMovieSections.forEach(section => {
            handleVideoSection(section);

            window.addEventListener("resize", () => handleVideoSection(section));
        });
    })
}


// Backdrop height

const heroSectionsForBackdrops = document.querySelectorAll(".hero");

if (heroSectionsForBackdrops?.length > 0) {
    const handleBackdrops = (backdrops) => {
        const first = backdrops[0];

        if (window.innerWidth > 991.98) {
            const secondHeight = backdrops[1].offsetHeight;

            first.style.height = `${secondHeight}px`;
        } else {
            first.style.height = "auto";
        }

    }
    heroSectionsForBackdrops.forEach(element => {
        const backdrops = element.querySelectorAll(".hero__backdrop");

        if (backdrops?.length > 1) {
            handleBackdrops(backdrops);

            window.addEventListener("resize", () => handleBackdrops(backdrops));
        }
    });
}